@tailwind base;
@tailwind components;
@tailwind utilities;

.react-select__control {
  border-radius: 0.5rem !important;
}

#check-group #check {
  stroke-dasharray: 0, 75px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.ticket-replies a,
.followup-description a,
.ck-editor__editable a {
  color: #3b82f6;
}

.ck-editor__editable {
  width: 100%;
  height: 234px;
  padding: 0 20px !important;
  border-radius: 0.5rem !important;
  border: 1px solid #cdcdcee7 !important;
  box-shadow: none !important;
}

.editor-border .ck-editor__editable {
  border: 1px solid rgb(185 28 28) !important;
}

.ck-editor__top {
  display: none !important;
}

.ck.ck-powered-by {
  display: none !important;
}
